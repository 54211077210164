<template>
	<div class="container">
		<div class="row clearfix displayFlex" id="clearfix" >
			<Nav_class></Nav_class>
			<div class="column class_study height85vh" style="margin: 0 15px;flex: 1 1 auto;">
				<div class="class_study_con">
					<div class="class_study_con_base">{{$store.state.teachlanguagedata.Testlist}} </div>
				</div>
				<div>
					<jobList ref="jobList"></jobList>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	
	import Nav_class from '../../components/Nav_class'
	import jobList from '../JobList/index.vue'
	export default {
		name: 'Class',
		components: {
			Nav_class,jobList
		},
		data() {
			return {
				planKind:'测验',
				teachId:JSON.parse(localStorage.getItem("teachuserID")),//老师Id
				chapterIds:null,//章节Id
				classId:null,//班级Id
			}
		},
		mounted() {
			
		},
		methods: {
			// 作业列表
			jobList(){
				this.chapterIds=JSON.parse(localStorage.getItem("teachchapters"))
				this.classId=JSON.parse(localStorage.getItem("teachclass_id"))
				this.$refs.jobList.init()
			}
		}
	}
</script>

<style scoped="scoped">
	/deep/ .el-progress-bar {
		width: 80%;
	}
</style>
